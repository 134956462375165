import { useRequestURL } from "#app";
import { isAllowLocalStorage } from "~/helpers/permissions";
import { DEFAULT_LANG } from "~/constants";
import { isServer } from "~/helpers/env";

const LOCAL_STORAGE_KEY = "hungryhub_lang";

// return 'en' from 'en-US'
export function getBrowserLang() {
  const browserLang = isServer ? "" : navigator.language;
  const split = browserLang.split("-");
  if (split.length === 2) {
    if (split[0] === "en" || split[0] === "th") {
      return split[0];
    }
    return null;
  }
  return null;
}

// get lang from url working on both server-side and client-side.
export function getLangFromURL() {
  const url = useRequestURL();
  const { pathname, searchParams } = url;

  // get lang from pathname
  const splitPath = pathname.split("/");
  const potentialLang = splitPath.length ? splitPath[1] : null;
  if (potentialLang && potentialLang.length === 2) {
    return potentialLang;
  }

  // get lang from query string
  const langQueryParam = searchParams.get("lang");
  if (langQueryParam) {
    return langQueryParam;
  }
  return null;
}

export function getFinalLang() {
  const lang = getLangFromURL();
  if (lang) return lang;

  // comment this because cause hydration miss match issue
  // const browserLang = getBrowserLang();
  // if (browserLang) {
  //   return browserLang;
  // }
  return DEFAULT_LANG;
}

export function setStoredLang(lang: string) {
  if (isAllowLocalStorage()) {
    localStorage.setItem(LOCAL_STORAGE_KEY, lang);
  }
}

export function getStoredLang() {
  if (isAllowLocalStorage()) {
    const lang = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lang) {
      if (lang === "en" || lang === "th") {
        return lang;
      }
      return "";
    }
    return "";
  }
  return "";
}

export function getPreferedLang() {
  const storedLang = getStoredLang();
  const browserLang = getBrowserLang();
  return storedLang || browserLang || DEFAULT_LANG;
}
