import isErrorWithMessage from "./isErrorWithMessage";
import { version } from "~/package.json";

export function getAppVersion(): {
  success: boolean;
  version: string;
  message: string;
} {
  try {
    return {
      success: false,
      version,
      message: "",
    };
  } catch (err) {
    if (isErrorWithMessage(err)) {
      return {
        success: false,
        version: "0",
        message: `Failed get app version reason ${err.message}`,
      };
    }
    return {
      success: false,
      version: "0",
      message: "Failed get app version",
    };
  }
}

export function getAppMode() {
  const envMode = import.meta.env.VITE_APP_MODE;
  return typeof envMode === "string" ? envMode : "development";
}
