import { defineStore } from "pinia";
import {
  object,
  string,
  boolean,
  array,
  type Input,
  partial,
  parse,
  optional,
  enumType,
  pick,
  nullish,
} from "valibot";
import type { APIConfigResponse } from "~/types/Common";
import { DEFAULT_ERROR_MESSAGE, DEFAULT_LANG } from "~/constants";
import { packagePaymentSchema } from "~/types/Pack";
import { citySchema } from "~/types/City";
import { useHandleError } from "~/composables/useHandleError";

interface urlVoucherBanner {
  mobile: string;
  desktop: string;
}

const appConfigSchema = object({
  allowSearchByCity: boolean(),
  isLoading: boolean(),
  useImageCdn: boolean(),
  isHybrid: boolean(),
  isProduction: boolean(),
  cvId: nullish(string()),
  availableCities: array(citySchema),
  languageOptions: array(string()),
  selectedCity: string(),
  selectedCityId: string(),
  availablePaymentMethod: array(packagePaymentSchema),
  lang: string(),
  subscribeMarketingReward: string(),
  debug: boolean(),
  clientType: enumType(["android", "ios", "web", "Android", "Ios"]),
  mock: boolean(),
  mockData: array(enumType(["restaurant", "package", "config", "banner"])),
  mockPackages: optional(
    object({
      ayce: object({
        enable: optional(boolean()),
      }),
      pp: object({
        enable: optional(boolean()),
      }),
      xp: object({
        enable: optional(boolean()),
      }),
      hah: object({
        enable: optional(boolean()),
      }),
    })
  ),
  features: object({
    ticketAvailabilityCheck: boolean(),
  }),
  searchPageLayout: string(),
  callerDomain: string(),
  apiDoamin: string(),
  pegasusDomain: string(),
  channel: optional(string()),
});

type AppConfig = Input<typeof appConfigSchema>;
type PackagePayment = Input<typeof packagePaymentSchema>;

const appConfig: AppConfig = {
  availablePaymentMethod: [
    "promptpay",
    "credit_card",
    "true_wallet",
    "shopee_pay",
  ],
  allowSearchByCity: false,
  availableCities: [],
  useImageCdn: import.meta.env.VITE_APP_MODE === "production",
  isProduction: import.meta.env.VITE_APP_MODE === "production",
  isLoading: false,
  isHybrid: false,
  lang: DEFAULT_LANG,
  languageOptions: ["en", "th"],
  selectedCity: "Bangkok",
  selectedCityId: "",
  subscribeMarketingReward: "฿100",
  clientType: "web",
  debug: false,
  mock: false,
  mockData: [],
  features: {
    ticketAvailabilityCheck: true,
  },
  searchPageLayout: "list",
  callerDomain: "",
  apiDoamin: "",
  pegasusDomain: import.meta.env.VITE_PEGASUS_DOMAIN,
  channel: "",
};

const useConfigStore = defineStore("config", {
  state: () => {
    return {
      ...appConfig,
      backendConfig: {
        enableGiftCardFeature: false,
        weTravelTogetherIconUrl: "",
        showDistanceOnSearchPage: false,
        cultureLocale: "",
        availableLocales: [],
        cdnUrl: "",
        cvId: "",
        appTitle: "",
        appDescription: "",
        totalRestaurant: 0,
        totalCovers: 0,
        supportPhone: "",
        maxImageFileSizeInMb: 0,
        selectedPaymentProvider: {
          promptpay: "",
          cc: "",
        },
        restaurantPriceRange: {
          min: 0,
          max: 0,
          currency: "",
        },
        promptpayUnsupportedBanks: [],
        promptpayCountdown: 0,
        termConditionBigGroup: "",
        gbPrimepayPublicKey: "",
        cities: [],
        dynamicPoints: {
          reviewReservation: 0,
          newUserVoucherPoint: 0,
          earlyBird: {
            maxReviews: 0,
            point: 0,
          },
          referrerRewardPoint: 0,
        },
        packageList: {
          ayce: "",
          pp: "",
          xp: "",
          hah: "",
          hs: "",
          sm: "",
          bfp: "",
        },
        packageTagLines: {
          ayce: "",
          pp: "",
          xp: "",
          hah: "",
          hs: "",
          sm: "",
          bfp: "",
        },
        useAsyncBooking: false,
        wttLinkUrl: "",
        bannerVoucherUrl: "",
        mobileBannerVoucherUrl: "",
        webV2Host: "",
        limitedSeatsShowing: "",
        deliveryFeeCurrency: "",
        deliveryFeePerKmInBaht: 0,
        freeDeliveryFeeThresholdInBaht: 0,
        deliveryRadius: 0,
        minGroupBookingPartySize: 0,
        deliveryFeeInBaht: 0,
        features: {
          enableMapbox: true,
          enableSaveAddress: false,
          enableSaveCc: false,
          enablePreselectMenu: false,
          enableUseNewCheckout: false,
          enableUseNewCheckoutWithList: {
            data: [],
            isFullyEnabled: false,
          },
          firstAppVoucher: false,
          voucherInMarketplace: false,
        },
      } as APIConfigResponse["data"],
      version: "",
    };
  },
  getters: {
    allowedPaymentMethod(): PackagePayment[] {
      if (this.isProduction) {
        return ["credit_card", "promptpay"];
      }
      return this.availablePaymentMethod;
    },
    isVoucherFeatureEnabled(): boolean {
      return this.backendConfig.enableGiftCardFeature;
    },
    urlVoucherBanner(): urlVoucherBanner {
      return {
        mobile: this.backendConfig.mobileBannerVoucherUrl,
        desktop: this.backendConfig.bannerVoucherUrl,
      };
    },
    allowUseAsyncBooking(): boolean {
      return this.backendConfig.useAsyncBooking;
    },
    isWebClient(): boolean {
      return this.clientType === "web";
    },
    isIosClient(): boolean {
      return this.clientType === "ios";
    },
  },
  actions: {
    async getConfig() {
      try {
        const { getConfig } = await import("~/services/common/getConfig");
        const { data, isSuccess, message } = await getConfig();
        if (isSuccess && data) {
          this.backendConfig = data.data;
        }
        return {
          success: isSuccess,
          message,
        };
      } catch (err) {
        useHandleError({
          err,
          defaultErrorMessage: `${DEFAULT_ERROR_MESSAGE}, failed get config`,
        });
      }
    },
    setAppConfig(param: {
      isHybrid?: AppConfig["isHybrid"];
      lang?: AppConfig["lang"];
      debug?: AppConfig["debug"];
      cvId?: AppConfig["cvId"];
      clientType?: AppConfig["clientType"];
      useImageCdn?: AppConfig["useImageCdn"];
      mock?: AppConfig["mock"];
      mockData: AppConfig["mockData"];
      mockPackages: AppConfig["mockPackages"];
      searchPageLayout?: AppConfig["searchPageLayout"];
      callerDomain?: AppConfig["callerDomain"];
    }) {
      try {
        const configurableAppConfig = pick(partial(appConfigSchema), [
          "lang",
          "debug",
          "clientType",
          "useImageCdn",
          "mock",
          "cvId",
          "mockData",
          "mockPackages",
          "isHybrid",
          "searchPageLayout",
          "callerDomain",
        ]);
        const {
          lang,
          debug,
          clientType,
          useImageCdn,
          cvId,
          mock,
          mockData,
          mockPackages,
          isHybrid,
          searchPageLayout,
          callerDomain,
        } = parse(configurableAppConfig, param);

        this.setClientType(clientType || "web");
        this.debug = debug || false;
        this.cvId = cvId || "";
        this.lang =
          lang && this.languageOptions.includes(lang) ? lang : DEFAULT_LANG;
        this.useImageCdn = useImageCdn || true;
        this.mock = mock || false;
        this.mockData = mockData || [];
        if (mockPackages) {
          this.mockPackages = mockPackages;
        }
        if (isHybrid) {
          this.isHybrid = true;
        }
        this.searchPageLayout = searchPageLayout || "list";
        this.callerDomain = callerDomain || "";
      } catch (err) {
        useHandleError({
          err,
          defaultErrorMessage: "Failed parse app config",
        });
      }
    },
    setClientType(clientType: AppConfig["clientType"]) {
      this.clientType = clientType.toLowerCase() as AppConfig["clientType"];
    },
  },
});

export default useConfigStore;
