import humps from "humps";
import { ASSET_BASE_URL } from "~/constants";
import useConfigStore from "~/stores/config";

export function isContainHttp(link: string) {
  if (typeof link !== "string") {
    return false;
  }
  if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
    return true;
  }
  return false;
}

export function isContainQueryString(link: string) {
  return !!link.includes("?");
}

export async function historyPushState(
  stateName: string,
  stateValue: string | number | boolean
) {
  const { useRouter } = await import("#imports");
  const router = useRouter();
  if (stateName) {
    const key = humps.decamelize(stateName);
    const query = {
      ...router.currentRoute.value.query,
      [key]: `${stateValue}`,
    };
    router.push({
      query: { ...query },
    });
  }
}

export async function historyRemoveState(stateName: string) {
  const { useRouter } = await import("#imports");
  const router = useRouter();
  if (stateName) {
    const query = { ...router.currentRoute.value.query };
    const key = humps.decamelize(stateName);
    delete query[key];
    if (!Object.keys(query).length) {
      query[""] = null;
    }
    router.replace({
      query: { ...query },
    });
  }
}

export function rebuildAssetURL(url: string) {
  // don't use mutator if in histoire story
  if (import.meta.env.VITE_DISABLE_IMAGE_RESIZE_SERVICE) {
    return url;
  }
  const configStore = useConfigStore();
  const cdnDomain = configStore.backendConfig.cdnUrl || ASSET_BASE_URL;
  if (typeof url === "string" && url.length) {
    if (url.includes("http") === false) {
      if (url.charAt(0) === "/") {
        return `${cdnDomain}${url}`;
      }
      return `${cdnDomain}/${url}`;
    }
    if (configStore.useImageCdn) {
      const base = new URL(cdnDomain);
      const asset = new URL(url);
      asset.hostname = base.hostname;
      return asset.toString();
    }
    return url;
  }
  return url;
}

export function isValidUrl(urlString: string) {
  try {
    const url = new URL(urlString);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (error) {
    return false;
  }
}
