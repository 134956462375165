import { setupI18n, loadLocaleMessages } from "../locales/i18nOption";
import { defineNuxtPlugin, useRequestURL } from "#imports";
import { getFinalLang } from "~/services/common/language";

async function loadDayjsLang(lang: string) {
  if (lang === "en") {
    await import("dayjs/locale/en");
  } else {
    await import("dayjs/locale/th");
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  let lang = getFinalLang();
  const url = useRequestURL();
  const pathName = url.pathname;
  const splitPath = pathName.split("/");
  if (splitPath.length) {
    const firstPath = splitPath[1];
    if (firstPath.length === 2 && ["en", "th"].includes(firstPath)) {
      lang = firstPath;
    }
  }
  const i18n = setupI18n({ lang });
  await loadDayjsLang(lang);
  await loadLocaleMessages(i18n, lang);
  nuxtApp.vueApp.use(i18n);
});
