import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { retryablePromise } from "~/helpers/retryablePromise";
const plugin = {
  isSameOrBefore: false,
  isSameOrAfter: false,
  isBetween: false,
  timezone: false,
  utc: false,
  duration: false,
  relativeTime: false,
};

dayjs.extend(customParseFormat);

async function addIsSameOrBeforePlugin() {
  if (plugin.isSameOrBefore) {
    return;
  }
  const module = (
    await retryablePromise(() => import("dayjs/plugin/isSameOrBefore"))
  ).default;
  dayjs.extend(module);
}

async function addDurationPlugin() {
  if (plugin.duration) {
    return;
  }
  const module = (await retryablePromise(() => import("dayjs/plugin/duration")))
    .default;
  dayjs.extend(module);
}

async function addTimeZonePlugin() {
  if (plugin.timezone) {
    return;
  }
  const module = (await retryablePromise(() => import("dayjs/plugin/timezone")))
    .default;
  dayjs.extend(module);
}

async function addIsSameOrAfterPlugin() {
  if (plugin.isSameOrAfter) {
    return;
  }
  const module = (
    await retryablePromise(() => import("dayjs/plugin/isSameOrAfter"))
  ).default;
  dayjs.extend(module);
}

async function addIsBetweenPlugin() {
  if (plugin.isBetween) {
    return;
  }
  const module = (
    await retryablePromise(() => import("dayjs/plugin/isBetween"))
  ).default;
  dayjs.extend(module);
}

async function addUtcPlugin() {
  if (plugin.utc) {
    return;
  }
  const module = (await retryablePromise(() => import("dayjs/plugin/utc")))
    .default;
  dayjs.extend(module);
}

async function addRelativeTimePlugin() {
  if (plugin.relativeTime) {
    return;
  }
  const module = (
    await retryablePromise(() => import("dayjs/plugin/relativeTime"))
  ).default;
  dayjs.extend(module);
}

export {
  addIsSameOrBeforePlugin,
  addIsSameOrAfterPlugin,
  addIsBetweenPlugin,
  addUtcPlugin,
  addRelativeTimePlugin,
  addDurationPlugin,
  addTimeZonePlugin,
};

export default dayjs;
