export async function retryablePromise<T>(
  promiseFn: () => Promise<T>,
  retries = 3,
  delay = 700
): Promise<T> {
  let attempt = 0;
  while (attempt < retries) {
    try {
      return await promiseFn();
    } catch (error) {
      attempt++;
      if (attempt >= retries) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
  throw new Error("This should not be reachable");
}
