export const DEFAULT_LANG = "th";
export const API_MINOR_VERSION = 3;
export const API_MAJOR_VERSION = "v5";
export const API_DOMAIN = import.meta.env.NUXT_PUBLIC_API_DOMAIN;
export const API_PROVIDER = "hungryhub";
export const API_BASE_URL = "api";
export const API_CLIENT_TYPE = "web";
export const BASE_URL_WEB_PRODUCTION = "https://web.hungryhub.com";
export const DEFAULT_AVATAR =
  "https://d38lri8pyzrvor.cloudfront.net/mr_hungry_avatar.png";
export const ASSET_BASE_URL = "https://d38lri8pyzrvor.cloudfront.net";
export const SOURCE = "website";
export const PROVIDER = "hungryhub";
export const UTM_SOURCE = "utm_source";
export const UTM_MEDIUM = "utm_medium";
export const UTM_VERSION = "utm_version";
export const PORTAL_FOR_MODAL_SELECTOR = "portal-for-modal";
export const ORDER_NOW = "order-now";
export const ORDER_LATER = "order-latter";
export const PICK_UP = "pick up";
export const DELIVERY = "delivery";
export const DINE_IN = "dine_in";
export const EXPERIENCE = "experience";
export const RESERVATION_PAID = "paid";
export const RESERVATION_PENDING_ARRIVAL = "pending_arrival";
export const RESERVATION_PENDING_CONFIRMATION = "pending_confirmation";
export const RESERVATION_WAITING_FOR_PAYMENT = "waiting_for_payment";
export const RESERVATION_ARRIVED = "arrived";
export const RESERVATION_CANCELED = "cancelled";
export const RESERVATION_REJECTED = "rejected";
export const RESERVATION_NO_SHOW = "no_show";
export const RESERVATION_CANCEL_MODIFIED = "cancel_modified";
export const RESERVATION_BEING_PREPARED = "order_being_prepared";
export const PACKAGE_PREFERENCE_DINE_IN = "dine in package";
export const PACKAGE_PREFERENCE_DELIVERY_PICK_UP = "delivery/pick up package";
export const PACKAGE_PREFERENCE_XPERIENCE = "xperience package";
export const MIN_POINT_TO_REDEEM = 200;
export const MAX_POINT_TO_REDEEM = 1000;
export const IOS_STORE_LINK =
  "https://apps.apple.com/in/app/hungry-hub-dining-offer-app/id879303325";
export const ANDROID_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.hb.hungryhub";
export const PRODUCTION_URL = "https://web.hungryhub.com";
export const ROUTE_HOME_PAGE = "HomePage";
export const ROUTE_SEARCH_PAGE = "SearchPage";
export const ROUTE_RESTAURANT_PAGE = "RestaurantPage";
export const ROUTE_VOUCHER_PAGE = "VoucherPage";
export const ROUTE_BUY_VOUCHER_PAGE = "BuyVoucherPage";
export const ROUTE_BUY_RESTAURANT_VOUCHER_PAGE = "BuyRestaurantVoucherPage";
export const ROUTE_BUY_RESTAURANT_VOUCHER_SIDEBAR =
  "BuyRestaurantVoucherSidebar";
export const ROUTE_BOOKING_LANDING_PAGE = "Landing";
export const ROUTE_VOUCHER_LANDING_PAGE = "LandingVoucher";
export const ROUTE_VOUCHER_QR_PAYMENT_LANDING_PAGE = "VoucherQRPaymentLanding";
export const ROUTE_PROFILE_VOUCHER = "ProfileVoucher";
export const ROUTE_PROFILE_HOME = "ProfileHome";
export const ROUTE_PROFILE_EDIT = "ProfileEdit";
export const ROUTE_PROFILE_PASSWORD = "ProfilePassword";
export const ROUTE_PROFILE_FAVOURITE = "ProfileFavourite";
export const ROUTE_PROFILE_HISTORY = "ProfileHistory";
export const ROUTE_PROFILE_POINT = "ProfilePoint";
export const ROUTE_PROFFILE_POINT_HISTORY = "ProfilePointHistory";
export const ROUTE_PROFILE_BENEFITS = "ProfileBenefits";
export const ROUTE_PAYMENT_SUCCESS = "ReservationPaymentSuccess";
export const ROUTE_PAYMENT_FAILED = "ReservationPaymentFailed";
export const ROUTE_PAYMENT_OTP = "ReservationPaymentOTP";
export const ROUTE_GROUP_LANDING_PAGE = "group-landing";
export const ROUTE_NOT_FOUND = "NotFound";
export const PAYMENT_CREDIT_CARD = "card";
export const PAYMENT_PROMPTPAY = "promptpay";
export const PAYMENT_TRUEWALLET = "true_wallet";
export const PAYMENT_SHOPEEPAY = "shopee_pay";
export const DELIVERY_METHOD_PICKUP_AND_DELIVERY = "self_pickup_and_delivery";
export const DELIVERY_METHOD_DELIVERY = "delivery";
export const DELIVERY_METHOD_PICKUP = "self_pickup";
export const PRICING_TYPE_ALA_CARTE = "ala_carte";
export const PACKAGE_CODE_AYCE = "ayce";
export const PACKAGE_CODE_PP = "pp";
export const PACKAGE_CODE_HAH = "hah";
export const PACKAGE_CODE_BFP = "bfp";
export const PACKAGE_CODE_SM = "sm";
export const PACKAGE_CODE_HS = "hs";
export const PACKAGE_CODE_XP = "xp";
export const SUPPORTED_PACKAGE_TYPE = [
  PACKAGE_CODE_AYCE,
  PACKAGE_CODE_PP,
  PACKAGE_CODE_HAH,
  PACKAGE_CODE_BFP,
  PACKAGE_CODE_SM,
  PACKAGE_CODE_HS,
  PACKAGE_CODE_XP,
];
export const SUPPORTED_PAYMENT_PROMPT_PAY = "promptpay";
export const SUPPORTED_PAYMENT_CREDIT_CARD = "credit_card";
export const SUPPORTED_PAYMENT_CC = "creditcard";
export const SUPPORTED_PAYMENT_QR = "qrcode";
export const SUPPORTED_PAYMENT_CC_QR = "qrcode_and_creditcard";
export const SUPPORTED_PAYMENT_SHOPEE_PAY = "shopee_pay";
export const SUPPORTED_PAYMENT_TRUE_WALLET = "true_wallet";
export const INVOLVE_ASIA_CLICK_ID = "ia_click_id";
export const BOOKING_FLOW_PREFERENCE_DATE_FIRST = "date_first";
export const BOOKING_FLOW_PREFERENCE_PACK_FIRST = "pack_first";
export const BOOKING_PAYMENT_METHOD_CREDIT_CARD = "credit_card";
export const BOOKING_PAYMENT_METHOD_PROMPT_PAY = "promptpay";
export const BOOKING_PAYMENT_METHOD_TRUE_WALLET = "true_wallet";
export const HUNGRY_HUB_VOUCHER = "hungry-hub-voucher";
export const ERROR_INVALID_DATE = "Invalid date, please choose another date";
export const PAYMENT_PROVIDER_OMISE = "omise";
export const PAYMENT_PROVIDER_GB_PRIMEPAY = "gb_primepay";
export const VOUCHER_CATEGORY_GIFT = "gift";
export const VOUCHER_CATEGORY_REDEMPTION = "redemption";
export const VOUCHER_CATEGORY_MARKETING = "marketing";
export const VOUCHER_CATEGORY_FIRST_APP = "first_app_voucher";
export const VOUCHER_USAGE_DEDUCTIBLE = "deductible";
export const ERROR_EXPIRED_TOKEN =
  "Your session has expired, Please login again";
export const OFFERS_FRAME_ID = "offers-frame";
export const PAYMENT_FRAME_ID = "payment-frame";
export const REDEEM_POINT_VOUCHER_NAME = "REDEEMCODE";
export const DEFAULT_ERROR_MESSAGE = "Oops, something went wrong";
export const FAILED_CONNECT_ERROR_MESSAGE = "Failed connect to server";
export const FAILED_CONNECT_TO_SERVER = "Failed connecting to server";
export const SINGAPORE_COUNTRY_ID = 198;
export const THAILAND_COUNTRY_ID = 218;
export const CURRENCY_SYMBOL = ["฿", "S$", "$"];
export const VOUCHER_REDEMPTION_NAME = "Points Redemption";
export const FILTER_BY_CUISINE = "filter_cuisine";
export const FILTER_BY_OTHER = "filter_other";
export const FILTER_BY_PACKAGE_TYPE = "filter_package_type";
export const FILTER_BY_LOCATION = "filter_location";
export const FILTER_BY_DINING_STYLE = "filter_dining_style";
export const CITY_NAME_ALL_CITIES = "All Cities";
export const CITY_NAME_BANGKOK = "Bangkok";
export const CITY_NAME_SINGAPORE = "Singapore";
export const LOCATION_MRT = "mrt_route";
export const LOCATION_BTS = "bts_route";
export const LOCATION_SHOPPING_MALL = "shopping_mall";
export const LOCATION_POPULAR_ZONE = "popular_zone";
export const PRICE_PER_PACKAGE = "per_pack";
export const PRICE_PER_PERSON = "per_person";
export const PRICE_PER_SET = "per_set";
export const PARTNER_AOA = "aoa";
export const PARTNER_TAGTHAI = "tagthai";
export const PARTNER_OPENRICE = "openrice";
// packages that have value more than `1` in attribute `per_pack`
export const PACKAGES_USING_TMA = [
  PACKAGE_CODE_XP,
  PACKAGE_CODE_PP,
  PACKAGE_CODE_HAH,
];
export const LOCAL_STORAGE_ANY_OVERLAY = "hh-any-overlay";
export const SORT_BY_PICE_ASC = "price asc";
export const SORT_BY_PRICE_DESC = "price desc";
export const SORT_BY_REVIEWS_SCORE_DESC = "reviews_score desc";
export const SORT_BY_MOST_REVIEW = "most_reviewed";
export const LOCAL_STORAGE_LOAD_BOOKING_TICKET_PAGE =
  "hh-load-booking-ticket-page";
export const LOCAL_STORAGE_IS_CHECKOUT_TICKET_PAGE =
  "hh-is-checkout-ticket-page";
export const NO_RESPONSE_ERROR_MESSAGE = "No response from server";
