<template>
  <button
    v-if="show"
    class="fixed left-[50%] top-2 z-[9999] flex translate-x-[-50%] items-center gap-1 rounded-md bg-purple-300 p-1 text-xs text-white"
    @click="show = !show"
  >
    <span class=""> {{ text }} </span>
    <IconClose class="w-2" />
  </button>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { getAppMode } from "~/helpers/appInformation";
import IconClose from "~icons/hh-icons/icon-close";

const show = ref(false);
const text = `Nuxt Project`;

onMounted(() => {
  show.value = getAppMode() === "staging";
});
</script>
