import { type UsePermissionReturn, usePermission } from "@vueuse/core";
import { isServer } from "./env";
import isErrorWithMessage from "./isErrorWithMessage";

function isAllowLocalStorage() {
  try {
    if (isServer) {
      return false;
    }
    if (window.localStorage) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

function isAllowCookie() {
  try {
    if (navigator.cookieEnabled) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

function locationAccessPermission(): {
  success: boolean;
  data: null | UsePermissionReturn;
  message: string;
} {
  try {
    return {
      success: true,
      message: "",
      data: usePermission("geolocation"),
    };
  } catch (err) {
    if (isErrorWithMessage(err)) {
      return {
        success: false,
        data: null,
        message: err.message,
      };
    }
    return {
      success: false,
      message: "Failed load location access module",
      data: null,
    };
  }
}

async function askUseCurrentLocation(param = {}) {
  const currentStatus = await checkCurrentPermission();
  if (currentStatus.state === "prompt" || currentStatus.state === "granted") {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        const defaultOption = {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        };
        const option = { ...defaultOption, ...param };
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geoLocation = {
              lat: 0,
              lng: 0,
            };
            geoLocation.lat = position.coords.latitude;
            geoLocation.lng = position.coords.longitude;
            resolve(geoLocation);
          },
          (param) => {
            const error = {
              data: param,
              message:
                "We cannot track your location properly, please type your address",
              cause: param,
            };
            reject(error);
          },
          option
        );
      }
    });
  }
}

async function checkCurrentPermission() {
  return await navigator.permissions.query({ name: "geolocation" });
}

export {
  isAllowCookie,
  isAllowLocalStorage,
  locationAccessPermission,
  askUseCurrentLocation,
};
