import { default as indexETPmgmIWC6Meta } from "/opt/build/repo/pages/[lang]/[slug]/index.vue?macro=true";
import { default as reviewsDoMY1TYLv2Meta } from "/opt/build/repo/pages/[lang]/bloggers/reviews.vue?macro=true";
import { default as bookingtaMoV5HABQMeta } from "/opt/build/repo/pages/[lang]/booking.vue?macro=true";
import { default as indexNTdbaWpJ5cMeta } from "/opt/build/repo/pages/[lang]/checkin/[slug]/index.vue?macro=true";
import { default as confirmationM6MULioLU0Meta } from "/opt/build/repo/pages/[lang]/confirmation.vue?macro=true";
import { default as cuisineVLD78iru9WMeta } from "/opt/build/repo/pages/[lang]/filter/cuisine.vue?macro=true";
import { default as dining_45style4RCbH3ABFeMeta } from "/opt/build/repo/pages/[lang]/filter/dining-style.vue?macro=true";
import { default as locationvrbpagXPSyMeta } from "/opt/build/repo/pages/[lang]/filter/location.vue?macro=true";
import { default as otheri5tXI03tuSMeta } from "/opt/build/repo/pages/[lang]/filter/other.vue?macro=true";
import { default as pricegmKbgLNX1DMeta } from "/opt/build/repo/pages/[lang]/filter/price.vue?macro=true";
import { default as indexA2Cym7QPQRMeta } from "/opt/build/repo/pages/[lang]/gift-card/buy/index.vue?macro=true";
import { default as indexvG1nqTYFV8Meta } from "/opt/build/repo/pages/[lang]/gift-card/index.vue?macro=true";
import { default as indexXAOtIYmHfrMeta } from "/opt/build/repo/pages/[lang]/home/index.vue?macro=true";
import { default as indexCQdMqPCVFRMeta } from "/opt/build/repo/pages/[lang]/member/[first-child]/[second-child]/[third-child]/index.vue?macro=true";
import { default as indexyr0WgyeX62Meta } from "/opt/build/repo/pages/[lang]/member/[first-child]/[second-child]/index.vue?macro=true";
import { default as indexlMVt9Rcn0oMeta } from "/opt/build/repo/pages/[lang]/member/[first-child]/index.vue?macro=true";
import { default as index0xFiEDPE0LMeta } from "/opt/build/repo/pages/[lang]/member/index.vue?macro=true";
import { default as _91id_93XHYJimL6jHMeta } from "/opt/build/repo/pages/[lang]/member/payment-methods/[id].vue?macro=true";
import { default as indexSg0kuMCbpXMeta } from "/opt/build/repo/pages/[lang]/member/payment-methods/index.vue?macro=true";
import { default as _91id_93aDXDEdNnrYMeta } from "/opt/build/repo/pages/[lang]/package/[id].vue?macro=true";
import { default as payment_45failedyy6yyBs9G2Meta } from "/opt/build/repo/pages/[lang]/payment-failed.vue?macro=true";
import { default as checkout_45ticketrbgm0JPGSyMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/book/checkout-ticket.vue?macro=true";
import { default as checkout5PNLsCPnPuMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/book/checkout.vue?macro=true";
import { default as step_451dL5DdB65EsMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/book/step-1.vue?macro=true";
import { default as step_4520XzTdKcOAYMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/book/step-2.vue?macro=true";
import { default as step_453BcgZkiaLbSMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/book/step-3.vue?macro=true";
import { default as _91id_93um7xQrXXFQMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/confirmation/[id].vue?macro=true";
import { default as indexIxfSh67xrOMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/index/information/index.vue?macro=true";
import { default as indexFIxYn0U4K1Meta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/index/reviews/index.vue?macro=true";
import { default as indexPgu20K5m1DMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/index.vue?macro=true";
import { default as index3m3rRewqy0Meta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/landing/voucher/[id]/index.vue?macro=true";
import { default as indexRzIH3nKdwQMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/landing/voucher/qr-payment/[id]/index.vue?macro=true";
import { default as indexEf18NtFqc1Meta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/package/[id]/index.vue?macro=true";
import { default as indexC0kL2AF9ZhMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/search/index.vue?macro=true";
import { default as indexbMV1dRenVUMeta } from "/opt/build/repo/pages/[lang]/restaurants/[slug]/voucher/[id]/index.vue?macro=true";
import { default as search_45suggestion0TpRvXumqXMeta } from "/opt/build/repo/pages/[lang]/search-suggestion.vue?macro=true";
import { default as searchFnqamH69F9Meta } from "/opt/build/repo/pages/[lang]/search.vue?macro=true";
import { default as survey6sWNIYHfO5Meta } from "/opt/build/repo/pages/[lang]/survey.vue?macro=true";
import { default as indexvZYdLMcKfvMeta } from "/opt/build/repo/pages/[lang]/tags/index.vue?macro=true";
import { default as _91id_93mzDyGrO7CUMeta } from "/opt/build/repo/pages/[lang]/voucher/[id].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
export default [
  {
    name: indexETPmgmIWC6Meta?.name ?? "lang-slug",
    path: indexETPmgmIWC6Meta?.path ?? "/:lang()/:slug()",
    meta: indexETPmgmIWC6Meta || {},
    alias: indexETPmgmIWC6Meta?.alias || [],
    redirect: indexETPmgmIWC6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsDoMY1TYLv2Meta?.name ?? "lang-bloggers-reviews",
    path: reviewsDoMY1TYLv2Meta?.path ?? "/:lang()/bloggers/reviews",
    meta: reviewsDoMY1TYLv2Meta || {},
    alias: reviewsDoMY1TYLv2Meta?.alias || [],
    redirect: reviewsDoMY1TYLv2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/bloggers/reviews.vue").then(m => m.default || m)
  },
  {
    name: bookingtaMoV5HABQMeta?.name ?? "lang-booking",
    path: bookingtaMoV5HABQMeta?.path ?? "/:lang()/booking",
    meta: bookingtaMoV5HABQMeta || {},
    alias: bookingtaMoV5HABQMeta?.alias || [],
    redirect: bookingtaMoV5HABQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/booking.vue").then(m => m.default || m)
  },
  {
    name: indexNTdbaWpJ5cMeta?.name ?? "lang-checkin-slug",
    path: indexNTdbaWpJ5cMeta?.path ?? "/:lang()/checkin/:slug()",
    meta: indexNTdbaWpJ5cMeta || {},
    alias: indexNTdbaWpJ5cMeta?.alias || [],
    redirect: indexNTdbaWpJ5cMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/checkin/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmationM6MULioLU0Meta?.name ?? "lang-confirmation",
    path: confirmationM6MULioLU0Meta?.path ?? "/:lang()/confirmation",
    meta: confirmationM6MULioLU0Meta || {},
    alias: confirmationM6MULioLU0Meta?.alias || [],
    redirect: confirmationM6MULioLU0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: cuisineVLD78iru9WMeta?.name ?? "lang-filter-cuisine",
    path: cuisineVLD78iru9WMeta?.path ?? "/:lang()/filter/cuisine",
    meta: cuisineVLD78iru9WMeta || {},
    alias: cuisineVLD78iru9WMeta?.alias || [],
    redirect: cuisineVLD78iru9WMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/filter/cuisine.vue").then(m => m.default || m)
  },
  {
    name: dining_45style4RCbH3ABFeMeta?.name ?? "lang-filter-dining-style",
    path: dining_45style4RCbH3ABFeMeta?.path ?? "/:lang()/filter/dining-style",
    meta: dining_45style4RCbH3ABFeMeta || {},
    alias: dining_45style4RCbH3ABFeMeta?.alias || [],
    redirect: dining_45style4RCbH3ABFeMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/filter/dining-style.vue").then(m => m.default || m)
  },
  {
    name: locationvrbpagXPSyMeta?.name ?? "lang-filter-location",
    path: locationvrbpagXPSyMeta?.path ?? "/:lang()/filter/location",
    meta: locationvrbpagXPSyMeta || {},
    alias: locationvrbpagXPSyMeta?.alias || [],
    redirect: locationvrbpagXPSyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/filter/location.vue").then(m => m.default || m)
  },
  {
    name: otheri5tXI03tuSMeta?.name ?? "lang-filter-other",
    path: otheri5tXI03tuSMeta?.path ?? "/:lang()/filter/other",
    meta: otheri5tXI03tuSMeta || {},
    alias: otheri5tXI03tuSMeta?.alias || [],
    redirect: otheri5tXI03tuSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/filter/other.vue").then(m => m.default || m)
  },
  {
    name: pricegmKbgLNX1DMeta?.name ?? "lang-filter-price",
    path: pricegmKbgLNX1DMeta?.path ?? "/:lang()/filter/price",
    meta: pricegmKbgLNX1DMeta || {},
    alias: pricegmKbgLNX1DMeta?.alias || [],
    redirect: pricegmKbgLNX1DMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/filter/price.vue").then(m => m.default || m)
  },
  {
    name: indexA2Cym7QPQRMeta?.name ?? "lang-gift-card-buy",
    path: indexA2Cym7QPQRMeta?.path ?? "/:lang()/gift-card/buy",
    meta: indexA2Cym7QPQRMeta || {},
    alias: indexA2Cym7QPQRMeta?.alias || [],
    redirect: indexA2Cym7QPQRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/gift-card/buy/index.vue").then(m => m.default || m)
  },
  {
    name: indexvG1nqTYFV8Meta?.name ?? "lang-gift-card",
    path: indexvG1nqTYFV8Meta?.path ?? "/:lang()/gift-card",
    meta: indexvG1nqTYFV8Meta || {},
    alias: indexvG1nqTYFV8Meta?.alias || [],
    redirect: indexvG1nqTYFV8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/gift-card/index.vue").then(m => m.default || m)
  },
  {
    name: indexXAOtIYmHfrMeta?.name ?? "lang-home",
    path: indexXAOtIYmHfrMeta?.path ?? "/:lang()/home",
    meta: indexXAOtIYmHfrMeta || {},
    alias: indexXAOtIYmHfrMeta?.alias || [],
    redirect: indexXAOtIYmHfrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexCQdMqPCVFRMeta?.name ?? "lang-member-firstchild-secondchild-thirdchild",
    path: indexCQdMqPCVFRMeta?.path ?? "/:lang()/member/:firstchild()/:secondchild()/:thirdchild()",
    meta: indexCQdMqPCVFRMeta || {},
    alias: indexCQdMqPCVFRMeta?.alias || [],
    redirect: indexCQdMqPCVFRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/member/[first-child]/[second-child]/[third-child]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyr0WgyeX62Meta?.name ?? "lang-member-firstchild-secondchild",
    path: indexyr0WgyeX62Meta?.path ?? "/:lang()/member/:firstchild()/:secondchild()",
    meta: indexyr0WgyeX62Meta || {},
    alias: indexyr0WgyeX62Meta?.alias || [],
    redirect: indexyr0WgyeX62Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/member/[first-child]/[second-child]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlMVt9Rcn0oMeta?.name ?? "lang-member-firstchild",
    path: indexlMVt9Rcn0oMeta?.path ?? "/:lang()/member/:firstchild()",
    meta: indexlMVt9Rcn0oMeta || {},
    alias: indexlMVt9Rcn0oMeta?.alias || [],
    redirect: indexlMVt9Rcn0oMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/member/[first-child]/index.vue").then(m => m.default || m)
  },
  {
    name: index0xFiEDPE0LMeta?.name ?? "lang-member",
    path: index0xFiEDPE0LMeta?.path ?? "/:lang()/member",
    meta: index0xFiEDPE0LMeta || {},
    alias: index0xFiEDPE0LMeta?.alias || [],
    redirect: index0xFiEDPE0LMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/member/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XHYJimL6jHMeta?.name ?? "lang-member-payment-methods-id",
    path: _91id_93XHYJimL6jHMeta?.path ?? "/:lang()/member/payment-methods/:id()",
    meta: _91id_93XHYJimL6jHMeta || {},
    alias: _91id_93XHYJimL6jHMeta?.alias || [],
    redirect: _91id_93XHYJimL6jHMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/member/payment-methods/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSg0kuMCbpXMeta?.name ?? "lang-member-payment-methods",
    path: indexSg0kuMCbpXMeta?.path ?? "/:lang()/member/payment-methods",
    meta: indexSg0kuMCbpXMeta || {},
    alias: indexSg0kuMCbpXMeta?.alias || [],
    redirect: indexSg0kuMCbpXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/member/payment-methods/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aDXDEdNnrYMeta?.name ?? "lang-package-id",
    path: _91id_93aDXDEdNnrYMeta?.path ?? "/:lang()/package/:id()",
    meta: _91id_93aDXDEdNnrYMeta || {},
    alias: _91id_93aDXDEdNnrYMeta?.alias || [],
    redirect: _91id_93aDXDEdNnrYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/package/[id].vue").then(m => m.default || m)
  },
  {
    name: payment_45failedyy6yyBs9G2Meta?.name ?? "lang-payment-failed",
    path: payment_45failedyy6yyBs9G2Meta?.path ?? "/:lang()/payment-failed",
    meta: payment_45failedyy6yyBs9G2Meta || {},
    alias: payment_45failedyy6yyBs9G2Meta?.alias || [],
    redirect: payment_45failedyy6yyBs9G2Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: checkout_45ticketrbgm0JPGSyMeta?.name ?? "lang-restaurants-slug-book-checkout-ticket",
    path: checkout_45ticketrbgm0JPGSyMeta?.path ?? "/:lang()/restaurants/:slug()/book/checkout-ticket",
    meta: checkout_45ticketrbgm0JPGSyMeta || {},
    alias: checkout_45ticketrbgm0JPGSyMeta?.alias || [],
    redirect: checkout_45ticketrbgm0JPGSyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/book/checkout-ticket.vue").then(m => m.default || m)
  },
  {
    name: checkout5PNLsCPnPuMeta?.name ?? "lang-restaurants-slug-book-checkout",
    path: checkout5PNLsCPnPuMeta?.path ?? "/:lang()/restaurants/:slug()/book/checkout",
    meta: checkout5PNLsCPnPuMeta || {},
    alias: checkout5PNLsCPnPuMeta?.alias || [],
    redirect: checkout5PNLsCPnPuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/book/checkout.vue").then(m => m.default || m)
  },
  {
    name: step_451dL5DdB65EsMeta?.name ?? "lang-restaurants-slug-book-step-1",
    path: step_451dL5DdB65EsMeta?.path ?? "/:lang()/restaurants/:slug()/book/step-1",
    meta: step_451dL5DdB65EsMeta || {},
    alias: step_451dL5DdB65EsMeta?.alias || [],
    redirect: step_451dL5DdB65EsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/book/step-1.vue").then(m => m.default || m)
  },
  {
    name: step_4520XzTdKcOAYMeta?.name ?? "lang-restaurants-slug-book-step-2",
    path: step_4520XzTdKcOAYMeta?.path ?? "/:lang()/restaurants/:slug()/book/step-2",
    meta: step_4520XzTdKcOAYMeta || {},
    alias: step_4520XzTdKcOAYMeta?.alias || [],
    redirect: step_4520XzTdKcOAYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/book/step-2.vue").then(m => m.default || m)
  },
  {
    name: step_453BcgZkiaLbSMeta?.name ?? "lang-restaurants-slug-book-step-3",
    path: step_453BcgZkiaLbSMeta?.path ?? "/:lang()/restaurants/:slug()/book/step-3",
    meta: step_453BcgZkiaLbSMeta || {},
    alias: step_453BcgZkiaLbSMeta?.alias || [],
    redirect: step_453BcgZkiaLbSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/book/step-3.vue").then(m => m.default || m)
  },
  {
    name: _91id_93um7xQrXXFQMeta?.name ?? "lang-restaurants-slug-confirmation-id",
    path: _91id_93um7xQrXXFQMeta?.path ?? "/:lang()/restaurants/:slug()/confirmation/:id()",
    meta: _91id_93um7xQrXXFQMeta || {},
    alias: _91id_93um7xQrXXFQMeta?.alias || [],
    redirect: _91id_93um7xQrXXFQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPgu20K5m1DMeta?.name ?? "lang-restaurants-slug",
    path: indexPgu20K5m1DMeta?.path ?? "/:lang()/restaurants/:slug()",
    meta: indexPgu20K5m1DMeta || {},
    alias: indexPgu20K5m1DMeta?.alias || [],
    redirect: indexPgu20K5m1DMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexIxfSh67xrOMeta?.name ?? "lang-restaurants-slug-index-information",
    path: indexIxfSh67xrOMeta?.path ?? "information",
    meta: indexIxfSh67xrOMeta || {},
    alias: indexIxfSh67xrOMeta?.alias || [],
    redirect: indexIxfSh67xrOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/index/information/index.vue").then(m => m.default || m)
  },
  {
    name: indexFIxYn0U4K1Meta?.name ?? "lang-restaurants-slug-index-reviews",
    path: indexFIxYn0U4K1Meta?.path ?? "reviews",
    meta: indexFIxYn0U4K1Meta || {},
    alias: indexFIxYn0U4K1Meta?.alias || [],
    redirect: indexFIxYn0U4K1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/index/reviews/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index3m3rRewqy0Meta?.name ?? "lang-restaurants-slug-landing-voucher-id",
    path: index3m3rRewqy0Meta?.path ?? "/:lang()/restaurants/:slug()/landing/voucher/:id()",
    meta: index3m3rRewqy0Meta || {},
    alias: index3m3rRewqy0Meta?.alias || [],
    redirect: index3m3rRewqy0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/landing/voucher/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRzIH3nKdwQMeta?.name ?? "lang-restaurants-slug-landing-voucher-qr-payment-id",
    path: indexRzIH3nKdwQMeta?.path ?? "/:lang()/restaurants/:slug()/landing/voucher/qr-payment/:id()",
    meta: indexRzIH3nKdwQMeta || {},
    alias: indexRzIH3nKdwQMeta?.alias || [],
    redirect: indexRzIH3nKdwQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/landing/voucher/qr-payment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEf18NtFqc1Meta?.name ?? "lang-restaurants-slug-package-id",
    path: indexEf18NtFqc1Meta?.path ?? "/:lang()/restaurants/:slug()/package/:id()",
    meta: indexEf18NtFqc1Meta || {},
    alias: indexEf18NtFqc1Meta?.alias || [],
    redirect: indexEf18NtFqc1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/package/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexC0kL2AF9ZhMeta?.name ?? "lang-restaurants-slug-search",
    path: indexC0kL2AF9ZhMeta?.path ?? "/:lang()/restaurants/:slug()/search",
    meta: indexC0kL2AF9ZhMeta || {},
    alias: indexC0kL2AF9ZhMeta?.alias || [],
    redirect: indexC0kL2AF9ZhMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexbMV1dRenVUMeta?.name ?? "lang-restaurants-slug-voucher-id",
    path: indexbMV1dRenVUMeta?.path ?? "/:lang()/restaurants/:slug()/voucher/:id()",
    meta: indexbMV1dRenVUMeta || {},
    alias: indexbMV1dRenVUMeta?.alias || [],
    redirect: indexbMV1dRenVUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/restaurants/[slug]/voucher/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: search_45suggestion0TpRvXumqXMeta?.name ?? "lang-search-suggestion",
    path: search_45suggestion0TpRvXumqXMeta?.path ?? "/:lang()/search-suggestion",
    meta: search_45suggestion0TpRvXumqXMeta || {},
    alias: search_45suggestion0TpRvXumqXMeta?.alias || [],
    redirect: search_45suggestion0TpRvXumqXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/search-suggestion.vue").then(m => m.default || m)
  },
  {
    name: searchFnqamH69F9Meta?.name ?? "lang-search",
    path: searchFnqamH69F9Meta?.path ?? "/:lang()/search",
    meta: searchFnqamH69F9Meta || {},
    alias: searchFnqamH69F9Meta?.alias || [],
    redirect: searchFnqamH69F9Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/search.vue").then(m => m.default || m)
  },
  {
    name: survey6sWNIYHfO5Meta?.name ?? "lang-survey",
    path: survey6sWNIYHfO5Meta?.path ?? "/:lang()/survey",
    meta: survey6sWNIYHfO5Meta || {},
    alias: survey6sWNIYHfO5Meta?.alias || [],
    redirect: survey6sWNIYHfO5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/survey.vue").then(m => m.default || m)
  },
  {
    name: indexvZYdLMcKfvMeta?.name ?? "lang-tags",
    path: indexvZYdLMcKfvMeta?.path ?? "/:lang()/tags",
    meta: indexvZYdLMcKfvMeta || {},
    alias: indexvZYdLMcKfvMeta?.alias || [],
    redirect: indexvZYdLMcKfvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/tags/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mzDyGrO7CUMeta?.name ?? "lang-voucher-id",
    path: _91id_93mzDyGrO7CUMeta?.path ?? "/:lang()/voucher/:id()",
    meta: _91id_93mzDyGrO7CUMeta || {},
    alias: _91id_93mzDyGrO7CUMeta?.alias || [],
    redirect: _91id_93mzDyGrO7CUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/[lang]/voucher/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  }
]