import { CURRENCY_SYMBOL } from "~/constants";
const stripHtmlTag = (text: string): string => {
  if (text) {
    return text.replace(/(<([^>]+)>)/gi, "").replace(/\s*/, "");
  }
  return "";
};

// to remove another number value
// example: 5,555Baht --> 5555
const convertToNumber = (param: string | number): number => {
  if (typeof param === "number") {
    return param;
  } else if (typeof param === "string" && param.length > 0) {
    // to remove character after ','
    // const split = param.split(".");
    // to remove character if it is not a number
    // const removeNonNumber = split[0].match(/\d+/g);
    // const finalNumber =
    //   removeNonNumber !== null ? parseInt(removeNonNumber.join("")) : 0;
    const replace = param.replace(/[^0-9]+/g, "");
    return parseInt(replace);
  }
  return 0;
};

const removePunctuation = (text: string): string => {
  const punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  if (text && text.length) {
    try {
      return text.replace(punctuation, "");
    } catch (err) {
      return text;
    }
  }
  return "";
};

const formatThousand = (param: number | string): string => {
  const value = convertToNumber(param);
  const intl = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return intl.format(value);
};

const moneyFormat = (param: number | string): string => {
  const value = convertToNumber(param);
  const intl = new Intl.NumberFormat("th-TH", {
    maximumFractionDigits: 0,
  });
  return `${intl.format(value)}฿`;
};

const formatMoney = ({
  val,
  symbol = "฿",
  additionalSign = false,
}: {
  val: string | number;
  symbol?: string;
  additionalSign?: boolean;
}) => {
  try {
    if (
      typeof val === "string" &&
      CURRENCY_SYMBOL.some((substring) => val.includes(substring))
    ) {
      if (additionalSign && symbol === "SGD") {
        return `S${val}`;
      }
      return val;
    }
    const parsedSymbol = symbol.includes("SGD")
      ? "S$"
      : symbol.includes("THB")
      ? "฿"
      : symbol;
    const intl = new Intl.NumberFormat();
    const formated = intl.format(convertToNumber(val));
    return `${parsedSymbol}${formated}`;
  } catch (err) {
    return val;
  }
};

const isValidJsonString = (str: string) => {
  try {
    if (typeof str !== "string") {
      return false;
    }
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const toSlug = (param: string) => {
  if (typeof param !== "string") {
    return "";
  }
  return param
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const isSlahSuffix = (param: string) => {
  if (typeof param !== "string") {
    return false;
  }
  return param.charAt(param.length - 1) === "/";
};

// Format number to K or M
// eg: 1000 => 1K , 1000000 => 1M+
function kFormatter(num: number) {
  const THOUSAND = 1000;
  const MILLION = 1000000;
  const absNum = Math.abs(num);
  const sign = Math.sign(num);

  return absNum >= MILLION
    ? sign * Number((absNum / MILLION).toFixed(1)) + "M+"
    : absNum >= THOUSAND
    ? sign * Number((absNum / THOUSAND).toFixed(1)) + "K"
    : sign * absNum;
}

function newTotalCoverFormatter(num: number) {
  if (num >= 1 && num <= 9) {
    return num;
  } else if (num >= 10 && num <= 99) {
    const base = Math.floor(num / 10) * 10;
    return base + "+";
  } else if (num >= 100 && num <= 999) {
    const base = Math.floor(num / 100) * 100;
    return base + "+";
  } else if (num >= 1000 && num <= 9999) {
    const base = Math.floor(num / 1000);
    return base + "k+";
  } else if (num >= 10000 && num <= 99999) {
    const base = Math.floor(num / 10000) * 10;
    return base + "k+";
  } else if (num >= 100000 && num <= 999999) {
    const base = Math.floor(num / 100000) * 100;
    return base + "k+";
  } else if (num >= 1000000 && num <= 999999999) {
    const base = Math.floor(num / 1000000);
    return base + "m+";
  } else {
    return num;
  }
}

const addCurrency = ({
  val,
  symbol = "฿",
  additionalSsign = false,
}: {
  val: string | number;
  symbol?: string;
  additionalSsign?: boolean;
}) => {
  if (
    typeof val === "string" &&
    CURRENCY_SYMBOL.some((substring) => val.includes(substring))
  ) {
    if (additionalSsign && symbol === "SGD") {
      return `S${val}`;
    }
    return val;
  }
  const parsedSymbol =
    typeof symbol !== "string"
      ? "฿"
      : symbol.includes("SGD")
      ? "S$"
      : symbol.includes("THB")
      ? "฿"
      : symbol;
  const intl = new Intl.NumberFormat();
  const formated = intl.format(convertToNumber(val));
  return `${parsedSymbol}${formated}`;
};

export {
  stripHtmlTag,
  convertToNumber,
  removePunctuation,
  isValidJsonString,
  toSlug,
  moneyFormat,
  formatThousand,
  formatMoney,
  isSlahSuffix,
  kFormatter,
  newTotalCoverFormatter,
  addCurrency,
};
