import humps from "humps";
import qs from "qs";
import { $fetch, type FetchOptions, FetchError } from "ofetch";
import { isEmpty } from "lodash-es";
import { API_BASE_URL, FAILED_CONNECT_TO_SERVER } from "../constants";
import { isContainHttp, isContainQueryString } from "~/helpers/url";
import { isSlahSuffix } from "~/helpers/string";
import type { ClientType } from "~/types/Common";

type paramConfig = {
  url: string;
  method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  headers?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  canRetry?: boolean;
  canCancel?: boolean;
  withCredentials?: boolean;
  lang?: string;
  camelizePayload?: boolean;
  version?: "v5" | "v6";
  abortController?: AbortController;
  useBaseURL?: boolean;
};
type MessageParam = {
  onRequestError: string;
};
type State = {
  data: unknown | null;
  httpStatus: number;
  error: {
    message: string;
    detail: unknown;
    shouldReport: boolean;
  };
};

let lang = "th";
let domain = "";
let clientType: ClientType = "web";
let defaultMessageOnRequestError = "";
export function createAbortController() {
  const controller = new AbortController();
  return controller;
}

export function setupInitialData(param: {
  lang: string;
  domain: string;
  clientType: ClientType;
  message: MessageParam;
}) {
  lang = param.lang;
  domain = isSlahSuffix(param.domain)
    ? param.domain.substring(0, param.domain.length - 1)
    : param.domain;
  clientType = param.clientType;
  const { message } = param;
  defaultMessageOnRequestError = message.onRequestError;
}

async function useHttp(paramConfig: paramConfig): Promise<State> {
  const state: State = {
    data: null,
    httpStatus: 0,
    error: {
      message: "",
      detail: undefined as unknown,
      shouldReport: true,
    },
  };

  if (domain.length === 0 || lang.length === 0) {
    throw new Error("Invalid baseURL or lang");
  }

  try {
    const usedLang = lang;
    const DEFAULT_HEADERS = {
      "Content-Type": "application/json",
      "X-HH-Language": paramConfig.lang || usedLang,
    };
    const REQUIRED_PARAMS = {
      client_type: clientType || "web",
      locale: paramConfig.lang || usedLang,
    };

    const isURLContainHttp = isContainHttp(paramConfig.url);
    const isURLContainQueryString = !!isContainQueryString(paramConfig.url);

    const options: FetchOptions = {
      method: paramConfig.method || "GET",
      headers: { ...DEFAULT_HEADERS, ...paramConfig.headers },
      retry: paramConfig.canRetry ? 3 : false,
      onResponseError({ response }) {
        state.error.message =
          response._data?.error ||
          response.statusText ||
          `Server response: ${response.status}`;
        state.error.detail = response._data?.status;
        state.httpStatus = response.status;
      },
      onRequestError({ error }) {
        const ignoreList = [
          "Failed connect to server",
          "The user aborted a request",
          "Failed to fetch",
        ];
        if (ignoreList.includes(error.message)) {
          state.error.shouldReport = false;
          state.error.message = defaultMessageOnRequestError || error.message;
        }
      },
    };

    if (paramConfig.abortController) {
      options.signal = paramConfig.abortController.signal;
    }

    const apiMajorVersion = paramConfig.version || "v5";

    if (!isURLContainHttp) {
      if (paramConfig.useBaseURL !== false) {
        options.baseURL = `${domain}/${API_BASE_URL}/${apiMajorVersion}`;
      } else {
        options.baseURL = `${domain}`;
      }
    }

    if (paramConfig.data) {
      if (!paramConfig.camelizePayload) {
        options.body = JSON.stringify(humps.decamelizeKeys(paramConfig.data));
      } else {
        options.body = paramConfig.data;
      }
    }

    if (isURLContainQueryString) {
      let parsedParamsStringify = qs.stringify(REQUIRED_PARAMS, {
        encode: false,
      });
      if (paramConfig.params) {
        parsedParamsStringify = qs.stringify(
          humps.decamelizeKeys({
            ...REQUIRED_PARAMS,
            ...paramConfig.params,
          }),
          { encode: false }
        );
      }
      paramConfig.url = `${paramConfig.url}&${parsedParamsStringify}`;
    } else {
      let parsedParams = REQUIRED_PARAMS as Record<string, any>;
      if (paramConfig.params) {
        parsedParams = humps.decamelizeKeys({
          ...REQUIRED_PARAMS,
          ...paramConfig.params,
        });
      }
      const parsedParamsStringify = qs.stringify(parsedParams, {
        encode: false,
      });
      paramConfig.url = `${paramConfig.url}?${parsedParamsStringify}`;
    }

    const response = await $fetch.raw(paramConfig.url, options);
    const responseData = response._data;
    state.httpStatus = response.status;
    if (isEmpty(responseData)) {
      state.error.message = FAILED_CONNECT_TO_SERVER;
      state.error.message = "empty response from server";
    } else {
      state.data = humps.camelizeKeys(responseData);
    }
  } catch (err) {
    if (err instanceof FetchError) {
      // prevent double handler if FetchError
      // already handled in onResponseError
      return state;
    }
    console.log("USEHTTP ERR", err);
    state.error.detail = err;
    state.error.message = "Something went wrong when setup http call";
  }
  return state;
}

export default useHttp;
