import { setupRollbar } from "~/lib/rollbar";
const isProd = process.env.NODE_ENV === "production";

type ReportParameter = {
  message: string;
  level: "critical" | "error" | "warning" | "debug" | "info";
  errorException?: any;
  object?: Record<string, unknown>;
};

const useReport = async (paramater: ReportParameter) => {
  if (isProd) {
    console.log(paramater);
    const rollbarInstance = await setupRollbar();
    if (rollbarInstance) {
      const logLevel = paramater.level || "debug";
      rollbarInstance.configure({ logLevel });
      rollbarInstance.log(
        paramater.message,
        paramater.object,
        paramater.errorException
      );
      return;
    }
  }
  console.log(paramater);
};

export { useReport };
export type { ReportParameter };
