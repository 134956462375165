import {
  object,
  string,
  nullable,
  nullish,
  optional,
  boolean,
  array,
  number,
  type Input,
  union,
  pick,
  merge,
  enumType,
  any,
} from "valibot";
import {
  SUPPORTED_PAYMENT_CREDIT_CARD,
  SUPPORTED_PAYMENT_PROMPT_PAY,
  SUPPORTED_PAYMENT_SHOPEE_PAY,
  SUPPORTED_PAYMENT_TRUE_WALLET,
  PACKAGE_CODE_AYCE,
  PACKAGE_CODE_PP,
  PACKAGE_CODE_HAH,
  PACKAGE_CODE_BFP,
  PACKAGE_CODE_SM,
  PACKAGE_CODE_HS,
  PACKAGE_CODE_XP,
  PRICE_PER_PACKAGE,
  PRICE_PER_PERSON,
  PRICE_PER_SET,
  PRICING_TYPE_ALA_CARTE,
} from "~/constants";

export const packagePaymentSchema = enumType([
  SUPPORTED_PAYMENT_PROMPT_PAY,
  SUPPORTED_PAYMENT_CREDIT_CARD,
  SUPPORTED_PAYMENT_SHOPEE_PAY,
  SUPPORTED_PAYMENT_TRUE_WALLET,
]);

export const supportedPackageTypeSchema = enumType([
  PACKAGE_CODE_AYCE,
  PACKAGE_CODE_PP,
  PACKAGE_CODE_HAH,
  PACKAGE_CODE_BFP,
  PACKAGE_CODE_SM,
  PACKAGE_CODE_HS,
  PACKAGE_CODE_XP,
]);
export const supportedPricingTypeSymSchema = enumType([
  PRICE_PER_PACKAGE,
  PRICE_PER_PERSON,
  PRICE_PER_SET,
  PRICING_TYPE_ALA_CARTE,
]);
export const MenusSchema = object({
  type: string(),
  data: any(),
});

export const OriginalPriceSchema = object({
  price: nullable(number()),
  currency: string(),
  format: string(),
});

export const CustomLabelSchema = object({
  name: string(),
  iconUrl: string(),
});

export const CustomLabel2Schema = object({
  name: string(),
  iconUrl: string(),
});

export const SpecialMenuSchema = object({
  id: number(),
  name: string(),
  qty: optional(number()),
});

export const SelectedSpecialMenuSchema = object({
  id: number(),
  qty: number(),
});

export const SelectedSpecialMenusSchema = object({
  id: number(),
  name: string(),
  quantity: number(),
});

export const SpecialMenuPackagesSchema = object({
  id: string(),
  packageName: string(),
  enableSpecialMenu: boolean(),
  specialMenuLimit: number(),
  specialMenus: array(SpecialMenuSchema),
  selectedQuantity: number(),
});

export const ImageSchema = object({
  thumb: string(),
  original: string(),
  originalSize: string(),
});

export const CustomLabel3Schema = object({
  name: string(),
  iconUrl: string(),
});

export const SubMenuSchema = object({
  name: string(),
  id: number(),
});

export const SubSectionSchema = object({
  name: nullable(string()),
  id: number(),
  quantityLimit: number(),
  subMenus: array(SubMenuSchema),
});

export const MenuSchema = object({
  id: union([number(), string()]),
  quantity: optional(number()), // user defined attribute
  name: optional(string(), ""),
  price: nullish(union([string(), number()])),
  currency: nullish(string()),
  priority: nullish(number()),
  description: nullish(string()),
  active: nullish(boolean()),
  image: nullish(ImageSchema),
  customLabel: nullish(CustomLabel3Schema),
  subSections: nullish(array(SubSectionSchema)),
});

export const MenuSectionSchema = object({
  id: nullish(union([number(), string()])),
  name: nullish(string()),
  quantityLimit: nullish(number()),
  totalQuantityLimit: nullish(number()),
  totalSelectedMenu: nullish(number()),
  menus: nullish(array(MenuSchema)),
});

export const TakeAwayPropertySchema = object({
  deliveryFeeInBaht: number(),
  deliveryFeeCurrency: string(),
  deliveryFeePerKmInBaht: number(),
  freeDeliveryFeeThresholdInBaht: number(),
  deliveryRadius: number(),
});

export const AyceRuleSchema = object({
  priceDescription: string(),
  price: string(),
  minSeat: nullable(number()),
  maxSeat: nullish(union([number(), string()])),
  duration: nullable(number()),
  tncLink: nullable(string()),
  kidsPriceRate: nullable(number()),
});

export const RuleSchema = object({
  priceDescription: string(),
  price: nullish(string()),
  minSeat: nullish(union([number(), string()])),
  maxSeat: nullish(union([number(), string()])),
  perPack: nullish(union([number(), string()])),
  duration: nullish(number()),
  kidsPriceRate: nullish(number()),
});

export const PackageInfoSchema = object({
  minPax: nullish(number()),
  maxPax: nullish(number()),
  timeLimit: nullish(string()),
  tncLink: nullish(string()),
  perPack: nullish(number()),
  kidsPriceRate: nullish(number()),
});

export const ImageCoverUrlSchema = object({
  thumb: string(),
  large: string(),
});

export const ScheduleSchema = object({
  open: string(),
  close: string(),
});

export const OpeningHourSchema = object({
  dayName: string(),
  schedule: ScheduleSchema,
});

export const TimeSlotsSchema = object({});

export const KidsPriceV2Schema = object({
  pricePolicy: string(),
  priceValue: string(),
});

export const pricingGroupsSchema = union([
  string(),
  object({
    groupSize: optional(number()),
    sizeToPay: optional(number()),
    priceFormat: optional(string()),
    price: optional(number()),
    currency: optional(string()),
    samplePricePerPeson: optional(number()),
    samplePricePerPesonFormat: optional(string()),
  }),
]);

export const packageGroupSchema = object({
  id: optional(number()),
  name: optional(string()),
});

export const PricingGroupSchema = optional(
  union([
    string(),
    object({
      currency: string(),
      groupSize: number(),
      price: number(),
      priceFormat: string(),
      samplePricePerPeson: number(),
      samplePricePerPesonFormat: string(),
      sizeToPay: number(),
    }),
  ])
);

export const DynamicPricingRuleSchema = object({
  title: nullish(string()),
  price: string(),
  priceCents: number(),
  minSeat: nullish(union([number(), string()])),
  maxSeat: nullish(union([number(), string()])),
  perPack: nullish(union([number(), string()])),
  categoryName: nullish(string()),
  days: nullish(array(string())),
  kidsPrice: nullish(string()),
  kidsPriceCents: nullish(number()),
  startDate: nullish(string()),
  endDate: nullish(string()),
  duration: nullish(number()),
  dynamicPricingType: nullish(string()),
  priceCurrency: string(),
  id: number(),
});

export const DynamicPricingSchema = object({
  mode: enumType(["per_person", "per_pack", "per_set"]),
  type: enumType(["by_day", "by_party_size", "normal", "per_pack"]),
  rules: array(DynamicPricingRuleSchema),
  kidsPricePolicy: string(),
});

export const PackAttributesSchema = object({
  menuLink: optional(string()),
  menus: nullish(MenusSchema),
  slug: nullish(string()),
  rank: nullish(number()),
  rankInRestaurantScope: nullish(number()),
  startDate: nullish(string()),
  endDate: nullish(string()),
  requireCc: nullish(boolean()),
  chargeType: nullish(string()),
  skipTimeSelection: nullish(boolean()),
  hhMenuV2HtmlPreviewLink: nullish(string()),
  availableMethods: nullish(string()),
  payNow: nullish(boolean()),
  customNetPrice: nullish(string()),
  noOfCourses: nullish(number()),
  defaultStartTime: nullish(string()),
  maxPackageQuantity: nullish(number()),
  chargePolicy: nullish(string()),
  chargePolicyLink: nullish(string()),
  menuQuantityLimit: nullish(number()),
  originalPrice: nullish(OriginalPriceSchema),
  hideMenuPrice: nullish(boolean()),
  isAddOn: nullish(boolean()),
  earnPoint: nullish(boolean()),
  customLabel: nullish(CustomLabelSchema),
  customLabels: nullish(array(CustomLabel2Schema)),
  enableSpecialMenu: nullish(boolean()),
  specialMenuLimit: nullish(number()),
  specialMenus: nullish(array(SpecialMenuSchema)),
  menuSections: nullish(array(MenuSectionSchema)),
  forDineIn: nullish(boolean()),
  hasCustomDeliveryFee: nullish(boolean()),
  takeAwayProperty: nullish(TakeAwayPropertySchema),
  acceptedCards: nullish(array(string())),
  menuType: nullish(
    union([enumType(["imenupro", "image", "menu_v2", "img"]), string()])
  ),
  name: nullish(string()),
  description: nullish(string()),
  lastBookingWasMade: nullish(string()),
  typeCode: nullish(supportedPackageTypeSchema),
  typeName: nullish(string()),
  ayceRules: nullish(array(AyceRuleSchema)),
  bfpRules: nullish(any()),
  ppRules: nullish(any()),
  rules: nullish(array(RuleSchema)),
  packageInfo: nullish(PackageInfoSchema),
  imageCoverUrl: nullish(ImageCoverUrlSchema),
  restaurantCuisine: nullish(string()),
  restaurantLocation: nullish(string()),
  restaurantName: nullish(string()),
  isVisibleForStaff: nullish(boolean()),
  openingHours: nullish(array(OpeningHourSchema)),
  isAcceptManyQuantity: nullish(boolean()),
  pricingTypeSym: nullish(supportedPricingTypeSymSchema),
  pricingType: nullish(string()),
  timeSlots: nullish(TimeSlotsSchema),
  pricingMode: nullish(string()),
  isAcceptVoucher: nullish(boolean()),
  largestTable: nullish(number()),
  customSeats: nullish(array(any())),
  acceptWeTravelTogether: nullish(boolean()),
  isAllowMix: nullish(boolean()),
  restaurantPackageVoucherDetail: nullish(string()),
  restaurantPackageVoucherTnc: nullish(string()),
  paymentTypes: nullish(array(string())),
  kidsPriceV2: nullish(array(KidsPriceV2Schema)),
  useKidsPrice: nullish(boolean()),
  comemorePayless: nullish(boolean()),
  pricingGroups: nullish(pricingGroupsSchema),
  packageGroup: nullish(packageGroupSchema),
  packageCoverUrl: nullish(string()),
  featured: nullish(boolean()),
  selectedSpecialMenus: optional(array(SelectedSpecialMenusSchema)),
  dynamicPricing: nullish(DynamicPricingSchema),
});

export const DataSchema = object({
  id: string(),
  type: string(),
});

export const RestaurantSchema = object({
  data: DataSchema,
});

export const RelationshipsSchema = object({
  restaurant: RestaurantSchema,
});

export const PackSchema = object({
  id: string(),
  type: string(),
  attributes: PackAttributesSchema,
  relationships: optional(RelationshipsSchema),
});

export const HybridPackSchema = merge([
  pick(PackAttributesSchema, [
    "name",
    "kidsPriceV2",
    "chargePolicy",
    "chargePolicyLink",
    "acceptWeTravelTogether",
    "requireCc",
    "isAcceptVoucher",
    "paymentTypes",
    "payNow",
    "rules",
    "pricingTypeSym",
    "isAcceptManyQuantity",
    "customNetPrice",
    "useKidsPrice",
    "typeCode",
    "availableMethods",
    "comemorePayless",
    "pricingGroups",
    "enableSpecialMenu",
    "specialMenuLimit",
    "specialMenus",
    "isAllowMix",
  ]),
  object({
    id: string(),
    quantity: number(),
    packageInfo: nullish(PackageInfoSchema),
    menuSections: optional(
      array(
        object({
          name: string(),
          id: union([number(), string()]),
          menus: array(
            object({
              name: optional(string(), ""),
              id: union([number(), string()]),
              quantity: optional(number(), 0),
            })
          ),
        })
      )
    ),
  }),
]);

const bookingPageCardSchema = object({
  id: string(),
  packageCoverUrl: string(),
  totalMaximumAllowance: number(),
  minimumAllowance: number(),
  typeCode: supportedPackageTypeSchema,
  name: string(),
  packageGroupName: string(),
  price: string(),
  quantity: number(),
  hasMenuSection: nullish(boolean()),
  isShowPrice: boolean(),
  menuSections: nullish(array(MenuSectionSchema)),
  isAllowMix: boolean(),
});

export type BookingPageCard = Input<typeof bookingPageCardSchema>;
export type PackFromAPI = Input<typeof PackSchema>;
export type Pack = PackFromAPI & {
  attributes: {
    quantity: number;
  };
};
export type PackagePayment = Input<typeof packagePaymentSchema>;
export type PackageRule = Input<typeof RuleSchema>;
export type PackageInfo = Input<typeof PackageInfoSchema>;
export type PackOpeningHour = Input<typeof OpeningHourSchema>;
export type HybridPack = Input<typeof HybridPackSchema>;
export type SupportedPackageType = Input<typeof supportedPackageTypeSchema>;
export type PricingGroups = Input<typeof pricingGroupsSchema>;
export type KidsPriceV2 = Input<typeof KidsPriceV2Schema>;
export type Menus = Input<typeof MenuSchema>;
export type SpecialMenus = Input<typeof SpecialMenuSchema>;
export type SelectedSpecialMenu = Input<typeof SelectedSpecialMenuSchema>;
export type SelectedSpecialMenus = Input<typeof SelectedSpecialMenusSchema>;
export type SpecialMenuPackages = Input<typeof SpecialMenuPackagesSchema>;
export type SupportedPricingTypeSym = Input<
  typeof supportedPricingTypeSymSchema
>;
export type MenuSection = Input<typeof MenuSectionSchema>;
export type DynamicPricing = Input<typeof DynamicPricingSchema>;
export type DynamicPricingRule = Input<typeof DynamicPricingRuleSchema>;
export type DyanmicePricingSummaries = {
  isSpecialDays: boolean;
  title: string;
  price: number | string;
  maxPrice: number | string;
  kidsPrice: string | number;
  maxKidsPrice: number | string;
  startDate: string | null;
  endDate: string | null;
  anyKidsPrice: boolean;
  needPopup: boolean;
};
export type PackageCardProps = {
  id: string | number;
  image: string;
  name: string;
  dynamicPricing: DynamicPricing;
  originalPrice?: string;
  date: string;
  featured: boolean;
  badges?: {
    icon: string;
    label: string;
  }[];
  bookable: boolean;
  preBook: boolean;
  description?: string;
  isLoading?: boolean;
  isPackageAvailable?: boolean;
  isExpired: boolean;
  allowShowPrice?: boolean;
  type: SupportedPackageType;
  quantity: number;
  menuSections?: MenuSection[];
  menuButton?: {
    isOpen: boolean;
  };
  comeMorePayLess?: boolean;
  pricingGroups?: PricingGroups;
};
