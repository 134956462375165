import { ValiError, flatten } from "valibot";
import { useReport, type ReportParameter } from "./useReport";
import isErrorWithMessage from "~/helpers/isErrorWithMessage";

export function useHandleError({
  err,
  defaultErrorMessage,
  sendReport = true,
  errorLevel = "error",
  errorPath,
  object,
}: {
  errorLevel?: ReportParameter["level"];
  err?: unknown;
  errorPath?: string;
  defaultErrorMessage: string;
  sendReport?: boolean;
  object?: ReportParameter["object"];
}) {
  let message = typeof errorPath === "string" ? `${errorPath}:` : "";
  if (err instanceof ValiError) {
    const mappedIssues = err.issues.map((issue) => {
      const message = issue.message;
      const paths = issue.path?.map((currentPath) => {
        const errorKey = currentPath.key;
        const errorValue =
          typeof currentPath.value === "string"
            ? currentPath.value
            : JSON.stringify(currentPath.value);
        return `key: ${errorKey} received: ${errorValue}`;
      });
      const parsedPaths = Array.isArray(paths) ? paths.toString() : "";

      return `${message} ${parsedPaths}`;
    });
    message = `${message} ${mappedIssues.toString()} ${JSON.stringify(
      flatten(err)
    )}`;
  } else if (isErrorWithMessage(err)) {
    message = message.concat(err.message);
  } else {
    message = message.concat(defaultErrorMessage);
  }
  if (sendReport) {
    useReport({
      level: errorLevel,
      message,
      errorException: err,
      object,
    });
  }
  return message;
}
