import { type I18nOptions, createI18n, type I18n } from "vue-i18n";

export const i18nOption: I18nOptions = {
  legacy: false,
  globalInjection: true,
  locale: "th",
  missingWarn: false,
  fallbackWarn: false,
};

export function setupI18n({ lang }: { lang: string }) {
  const i18n = createI18n(i18nOption);
  setI18nLanguage(i18n, lang);
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: string) {
  // @ts-ignore
  i18n.global.locale.value = locale;
}

export async function loadLocaleMessages(i18n: I18n, locale: string) {
  // load locale messages with dynamic import
  const messages = await import(`../locales/${locale}.json`);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);
}
