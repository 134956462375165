import { isEmpty } from "lodash-es";
import qs from "qs";
import { defineNuxtRouteMiddleware, navigateTo } from "#imports";

export default defineNuxtRouteMiddleware((to, from) => {
  if (isEmpty(to.query) && !isEmpty(from.query)) {
    const fromQuery = qs.stringify(from.query);
    const newURL = `${to.fullPath.split("?")[0]}?${fromQuery}`;
    return navigateTo(newURL);
  }
});
