<template>
  <div id="app">
    <AppModeButton />
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
    <!-- Sentinel element at the bottom of the content -->
    <div id="sentinel"></div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { setupInitialData } from "~/composables/useHttp";
import "./assets/index.css";
import useConfigStore from "~/stores/config";
import { onMounted, useRuntimeConfig, useRoute } from "#imports";
import useQueryString from "~/composables/useQueryString";
import { getFinalLang } from "~/services/common/language";
import dayjs from "~/lib/dayjs";
import { PARTNER_TAGTHAI } from "~/constants";
import AppModeButton from "~/components/AppModeButton.vue";

const { params } = useRoute();
const { getQueryString } = useQueryString();
const runtimeConfig = useRuntimeConfig();
const configStore = useConfigStore();
const {
  debug,
  clientType,
  apiDomain,
  useImageCdn,
  mock,
  mockData,
  mockPackages,
  partner,
  searchPageLayout,
  callerDomain,
  cvId,
} = getQueryString();
const usedLang = getFinalLang();
const usedApiDomain =
  typeof apiDomain === "string" && apiDomain.length > 0
    ? atob(apiDomain)
    : runtimeConfig.public.apiDomain;
const defaultMessageOnRequestError =
  partner === PARTNER_TAGTHAI
    ? "Network Error, Please check your internet connection"
    : "";
setupInitialData({
  lang: usedLang,
  domain: usedApiDomain,
  clientType: "web",
  message: {
    onRequestError: defaultMessageOnRequestError,
  },
});
useI18n().locale.value = usedLang;
dayjs.locale(usedLang);
configStore.setAppConfig({
  lang: usedLang,
  debug,
  cvId,
  clientType,
  useImageCdn,
  mock,
  mockData,
  mockPackages,
  isHybrid: params?.lang === "hybrid",
  searchPageLayout,
  callerDomain,
});

function setFontFamily() {
  // use different font if TH language
  if (usedLang === "th") {
    window.document
      .getElementsByTagName("html")[0]
      .classList.add("noto-sans-thai");
  }
}
async function setAllGroupLanding() {
  const { getAllGroupLanding } = await import(
    "~/services/group_landing/getAllGroupLanding"
  );
  getAllGroupLanding();
}
setAllGroupLanding();
onMounted(() => {
  setFontFamily();
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
</style>
