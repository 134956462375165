import { object, string, number, type Input } from "valibot";
export const citySchema = object({
  id: string(),
  type: string(),
  attributes: object({
    name: string(),
    homeDescription: string(),
    icon: object({
      url: string(),
    }),
    countryId: number(),
    countryIcon: object({
      url: string(),
    }),
  }),
});
export type City = Input<typeof citySchema>;
