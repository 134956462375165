import { defineNuxtPlugin, useReport } from "#imports";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    useReport({
      level: "error",
      errorException: error,
      message: "Error happened: handled in plugin/errorReport",
    });
  };
});
