export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"key":"gf-prefetch","rel":"dns-prefetch","href":"https://fonts.gstatic.com/"},{"key":"gf-preconnect","rel":"preconnect","href":"https://fonts.gstatic.com/","crossorigin":"anonymous"},{"key":"gf-origin-preconnect","rel":"preconnect","href":"https://fonts.googleapis.com/"}],"style":[],"script":[{"key":"gf-script","children":"(function(){\n        var h=document.querySelector(\"head\");\n        var m=h.querySelector('meta[name=\"head:count\"]');\n        if(m){m.setAttribute('content',Number(m.getAttribute('content'))+1);}\n        else{m=document.createElement('meta');m.setAttribute('name','head:count');m.setAttribute('content','1');h.append(m);}\n        var l=document.createElement('link');l.rel='stylesheet';l.href='https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Noto+Sans+Thai:wght@300;400;700;900&display=swap';h.appendChild(l);\n      })();"}],"noscript":[{"children":"<link rel=\"stylesheet\" href=\"https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Noto+Sans+Thai:wght@300;400;700;900&display=swap\">","tagPosition":"bodyOpen"}]}

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false